<template>
    <div class="row">
        <div class="col-12 text-center mb-3" style="font-weight: bold;">Bayar Sekarang</div>
        <div class="col-12">
            <div class="card" style="margin-bottom: 0.75rem;">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">NIS</label>
                        <label class="col-7 col-form-label text-right">{{dataSiswa.siswa_nis}}</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Nama</label>
                        <label class="col-7 col-form-label text-right">{{dataSiswa.siswa_nama}}</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Whatsapp</label>
                        <label class="col-7 col-form-label text-right">{{no_wa}}</label>
                    </div>
                    <!-- <div class="form-group row mb-0">
                        <label class="col-5 col-form-label">Email</label>
                        <label class="col-7 col-form-label text-right">{{email}}</label>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="col-12 mb-0">
            <div class="card">
                <div class="card-body custom-padding">
                    <div class="form-group row mb-0">
                        <label class="col-4 col-form-label">Channel</label>
                        <label class="col-8 col-form-label text-right">{{ChannelSelectedFromStore.channel_name}}</label>
                    </div>
                    <div class="form-group row mb-0" v-show="promoSelected">
                        <label class="col-4 col-form-label">{{detailPromoSelected.promo_nama}}</label>
                        <label class="col-8 col-form-label text-right">{{rupiah(totalPromo)}}</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-4 col-form-label">Total</label>
                        <label class="col-8 col-form-label text-right">{{rupiah(totalNominalTagihan + parseInt(feeAdmin))}}</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 text-center mb-3" style="font-weight: bold;">Untuk Pembayaran Kewajiban</div>
        <div class="col-12 mb-0">
            <div class="card" :class="{'card-bg-green' : (diskonTemp(tagihan).length > 0)}" style="margin-bottom: 0.75rem;" v-for="tagihan in getDetailTagihanChecked" :key="tagihan.bayar_siswa_id">
                <div class="card-body custom-padding">
                    <div class="form-group row align-items-center mb-0">
                        <label class="col-6 col-form-label">{{tagihan.wajib_nama}}</label>
                        <label class="col-6 col-form-label text-right">
                            <span :class="{'text-strike' : isStrike(tagihan)}">{{rupiah(tagihan.nominal_kurang_bayar)}}</span>
                            <span v-if="tagihan.kewajiban_keterangan"><br><small>({{tagihan.kewajiban_keterangan}})</small></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="col-12 mb-0" v-if="copyClicked">
            <b-alert show variant="warning" class="alert-copy">
                <i class="mdi mdi-checkbox-multiple-marked-outline me-2"></i><strong>Berhasil disalin!</strong>
              </b-alert>
        </div>
        <div class="col-12 mb-0">
            <div class="card card-bg-blue">
                <div class="card-body custom-padding">
                    <template v-if="ChannelSelectedFromStore.value == 'mandiri_va'">
                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">Kode Biller</label>
                        </div>

                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">
                                <strong ref="kode_biller" style="padding-right: 1rem;">{{kodeBiller}}</strong>
                                <span @click="copyKodeBiller()"><i class="fa fa-copy p-md-2"></i>&nbsp;Salin</span>
                            </label>
                        </div>
                    </template>

                    <template v-if="vaNumber && vaNumber != ''">
                        
                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label" v-if="ChannelSelectedFromStore.value == 'mandiri_va'">Kode Pembayaran</label>
                            <label class="col-12 col-form-label" v-else-if="['indomaret', 'cstore_indomaret', 'cstore_alfamart'].indexOf(ChannelSelectedFromStore.value) > '-1'">No. Tagihan</label>
                            <label class="col-12 col-form-label" v-else>No. Virtual Account</label>
                        </div>

                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">
                                <strong ref="va" style="padding-right: 1rem;">{{vaNumber}}</strong>
                                <span @click="copyVa()"><i class="fa fa-copy p-md-2"></i>&nbsp;Salin</span> 
                            </label>
                        </div>
                    </template>
                    
                    <template v-if="no_wallet && no_wallet != ''">
                        
                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">Nomor {{labelPhone}}</label>
                        </div>

                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">
                                <strong ref="no_wallet" style="padding-right: 1rem;">{{no_wallet}}</strong>
                            </label>
                        </div>
                    </template>

                    <template v-if="channelCountdown.indexOf(ChannelSelectedFromStore.value) == '-1'">
                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">Bayar Sebelum</label>
                        </div>
                        <div class="form-group row mb-0">
                            <label class="col-12 col-form-label">
                                <strong>{{dateExpired}}</strong>
                            </label>
                        </div>
                    </template>
                    <template v-else>
                    <div class="form-group row mb-0">
                        <label class="col-12 col-form-label">Segera selesaikan pembayaran sebelum waktu habis</label>
                    </div>
                    <div class="form-group row mb-0">
                        <label class="col-12 col-form-label">
                            <!-- <strong>{{timer_days.toString().padStart(2, "0")}} Hari {{timer_hours.toString().padStart(2, "0")}} Jam {{timer_minutes.toString().padStart(2, "0")}} Menit {{timer_seconds.toString().padStart(2, "0")}} Detik</strong> -->
                            <strong style="color: red; font-size: 1.5rem">Tersisa {{timer_seconds.toString().padStart(2, "0")}} Detik</strong>
                        </label>
                    </div>
                    </template>
                </div>
            </div>
        </div>

        <div class="col-sm-12 mb-2" v-if="qrUrl || qrString">
            <!-- <div ref="canvas_qr" style="border-radius: 0.55rem;overflow: hidden;"> -->
                <div ref="canvas_qr" class="card card-qr" style="position: relative; overflow: hidden; padding: 20px; margin-bottom: 10px;">
                    <div class="card-body custom-padding text-center qr-section" style="background: white;">
                        <!-- <p style="font-size: 8px; font-weight: bold; position: absolute; z-index: 1; bottom: 0; left: 10px; color: white;">*Berlaku Hingga : {{dateExpired}}</p> -->
                        
                        <!-- <img :src="getImgBlob('blob_1.png')" alt="" style="width: 300px; position: absolute !important; top: -150px; right: -150px;"> -->
                        <!-- <img :src="getImgBlob('blob_2.png')" alt="" style="width: 300px; position: absolute !important; top: 160px; left: -195px;"> -->

                        <div>
                            <img width="150px" class="img-fluid" :src="getImgChannel('channel_qris.png')" alt="">
                        </div>
                        <h3 style="font-size: 14px; margin: 0;">{{dataSiswa.siswa_nama}}</h3>
                        <div style="width: 300px">
                            <vue-qr ref="qr" :logoSrc="getImgLogoSS('logo_ss.png')" :text="qrString" :size="300" :logoScale="0.17" :logoCornerRadius="20" qid="qrSS"></vue-qr>
                        </div>
                        <h4 style="width: 100%; margin: 0;">
                            <label style="font-size: 14px;"><strong>{{rupiah(totalNominalTagihan + parseInt(feeAdmin))}}</strong></label>
                            <p style="font-size: 10px; line-height: normal; width: 80%; margin: auto;">Tagihan {{listNamaTagihan}}</p>
                            
                        </h4>
                        
                        <div><label style="font-size: 10px; font-weight: bold;">Menerima pembayaran melalui aplikasi di bawah ini</label></div>
                        
                        <div style="display: flex; justify-content: center; align-items: center; gap: 1rem;flex-wrap: wrap;">
                            <img :src="getListWalletImage('eWallet-QRIS.png')" class="img-fluid" style="width: 100%" alt="">
                        </div>

                        <div><label style="font-size: 10px; font-weight: bold;">*Berlaku Hingga : {{dateExpired}}</label></div>
                        <!-- <div style="height: 75px; position: relative;">
                            <img :src="getImgWave('wave_1.png')" alt="" style="position: absolute !important; left: -580px; bottom: -160px;">
                        </div> -->
                    </div>
                </div>
            <!-- </div> -->
            <button class="btn-download-qr wizard-btn btn-info"  style="width: 100%" @click="downloadQr(dataSiswa.siswa_nama);">Download QR</button>
        </div>
        
        <template v-if="ChannelSelectedFromStore.hasOwnProperty('metode_bayar') && ChannelSelectedFromStore.metode_bayar.length > 0">
            <div class="col-12 text-center mt-2 mb-2" style="font-weight: bold;">Panduan Pembayaran</div>
            <div class="col-12 mb-3">
                <div class="accordion" role="tablist" v-if="ChannelSelectedFromStore.hasOwnProperty('metode_bayar')">
                    <b-card no-body class="mb-1" v-for="(metode, index) in ChannelSelectedFromStore.metode_bayar" :key="metode.metode_name">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button class="btn-acccordion-header" block v-b-toggle="'accordion-' + index" variant="info" style="width: 100%;">{{metode.metode_name}}</b-button>
                    </b-card-header>
                    <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
                        <b-card-body>
                            <ol v-if="!metode.cara_bayar[0].hasOwnProperty('image')" style="padding-left: 1rem;">
                                <li class="mb-2" v-for="(step, indexStep) in metode.cara_bayar" :key="indexStep">
                                    <b-card-text v-html="replaceText(step.html, step.image)"></b-card-text>
                                </li>
                            </ol>
                            <template v-else v-for="(step, indexStep) in metode.cara_bayar">
                                <b-card-text class="mb-4" v-html="replaceText(step.html, step.image)" :key="indexStep"></b-card-text>
                            </template>
                        </b-card-body>
                    </b-collapse>
                    </b-card>
                </div>
            </div>
        </template>
        <!-- {{jumpApp}} -->
        <template v-if="(jumpApp && jumpApp != '') || (jumpWeb && jumpWeb != '')">
            <div class="col-12 mb-3 group-btn-jump" style="display: flex;">
                <a class="btn btn-primary" :href="jumpApp" v-if="jumpApp && jumpApp != ''" target="_BLANK">Go To App</a>
                <a class="btn btn-primary" :href="jumpWeb" v-if="jumpWeb && jumpWeb != ''" target="_BLANK">Go To Web</a>
            </div>
        </template>


        

        <!-- <div class="col-12 mb-0" v-if="qrUrl || qrString">
            <div class="card card-bg-blue">
                <div class="card-body custom-padding text-center qr-section">
                    <vue-qr v-if="qrString" :logoSrc="getImgLogoSS('logo_ss.png')" :text="qrString" :size="200" :logoScale="0.25" :logoCornerRadius="20" qid="qrSS"></vue-qr>
                    
                    <a v-if="qrUrl" style="display: flex; align-items: center;" class="btn btn-primary" :href="qrUrl" target="_BLANK">Download</a>
                </div>
            </div>
        </div> -->

    </div>
    <!-- end row -->
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import VueQr from 'vue-qr'
import { download } from "vue-downloader";
import moment from 'moment'
import Axios from "axios";


const urlNodeJs = process.env.VUE_APP_URL_NODE;
const paymentApiKey = process.env.VUE_APP_SS_PAYMENT_API_KEY;

const headersPaymentApi = {
    headers : {
        'Content-Type' : 'application/json',
        'X-API-KEY' : paymentApiKey
    },
    crossDomain : true
}

export default {
    name : 'final',
    props : [],
    data() {
        return {
            copyClicked : false,
            time_now : moment().unix(),
            t : ""
        }
    },
    components: {VueQr},

    methods : {
        getImgUrl(pet) {
            let images = require.context('@/assets/images/channel-bayar/', false, /\.png$/)
            return images('./' + pet)
        },
        getImgCaraBayar(pet){
            let images = require.context('@/assets/images/cara-bayar/', false, /\.png$/)
            return images('./' + pet)
        },
        getImgLogoSS(pet){
            let images = require.context('@/assets/images/brands/', false, /\.png$/)
            return images('./' + pet)
        },

        getListWalletImage(pet) {
            let images = require.context('@/assets/images/qr-design/images/', false, /\.png$/)
            return images('./' + pet)
        },
        getImgChannel(pet) {
            let images = require.context('@/assets/images/channel-bayar/', false, /\.png$/)
            return images('./' + pet)
        },
        getImgBlob(pet){
            let images = require.context('@/assets/images/qr-design/blob/', false, /\.png$/)
            return images('./' + pet)
        },
        getImgWave(pet){
            let images = require.context('@/assets/images/qr-design/wave/', false, /\.png$/)
            return images('./' + pet)
        },

        async downloadQr(nama_siswa){
            let printCanvas = this.$refs.canvas_qr;
            let copyCanvas = printCanvas.cloneNode(true);
            copyCanvas.style.position = "absolute";
            copyCanvas.style.width = "390px";
            copyCanvas.style.top = "-11390px";
            document.body.appendChild(copyCanvas);

            let canvas = await this.$html2canvas(copyCanvas, {});
            
            download(canvas.toDataURL("image/png"), nama_siswa + ".png");
            document.body.removeChild(copyCanvas);
        },

        copyVa(){
            let copyVa = this.$refs.va;

            /* Copy the text inside the text field */
            this.$copyText(copyVa.textContent);
            
            this.copyClicked = true;
            
            setTimeout(() => {
                this.copyClicked = false;
            }, 2000)
        },

        copyKodeBiller(){
            let copyKodeBiller = this.$refs.kode_biller;

            /* Copy the text inside the text field */
            this.$copyText(copyKodeBiller.textContent);

            this.copyClicked = true;
            
            setTimeout(() => {
                this.copyClicked = false;
            }, 2000)

        },

        replaceText(text, image = null){
            
            if(image){

                return text.replace("$va", this.vaNumber).replace("$biller", this.kodeBiller).replace("$nominal", this.rupiah(this.totalNominalTagihan + parseInt(this.feeAdmin))).replace("$image", this.getImgCaraBayar(image)).replace("$no_wallet", this.no_wallet).replace("$merchant", this.merchant_name);
            } else {
                return text.replace("$va", this.vaNumber).replace("$biller", this.kodeBiller).replace("$nominal", this.rupiah(this.totalNominalTagihan + parseInt(this.feeAdmin))).replace("$no_wallet", this.no_wallet).replace("$merchant", this.merchant_name);
            }
        },
        rupiah : (number) => new Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(number ?? 0).replace(",00", ''),

        diskonTemp(tagihan) {
            return tagihan?.diskon_temporary.filter(f => f.id_promo == this.promoSelected) ?? [];
        },
        isStrike(tagihan) {
            let totPromo = this.diskonTemp(tagihan).map((m) => m.bayar_diskon_nominal).reduce((acc, prev) => parseInt(acc) + parseInt(prev), 0);

            return (parseFloat(tagihan.nominal_kurang_bayar) === parseFloat(totPromo));
        }
    },

    computed : {
        ...mapGetters({
            ChannelSelectedFromStore : 'getChannelSelectedDetail',
            no_wa : 'getNoWa',
            email : 'getEmail',
            dataSiswa : 'getDataSiswa',
            listAdminFee : 'getListAdminFee',
            listAvailableChannel : 'getListAvailableChannel',
            getDetailTagihanChecked : 'getDetailTagihanChecked',
            vaNumber : 'getVaNumber',
            kodeBiller : 'getKodeBiller',
            dateExpired : 'getDateExpired',
            feeAdminIdBilling : 'getFeeAdminIdBilling',
            jumpApp : 'getJumpApp',
            jumpWeb : 'getJumpWeb',
            qrString : 'getQrString',
            qrUrl : 'getQrUrl',
            no_wallet : 'getNoWallet',
            merchant_name : 'getMerchantName',
            time_expired : 'getDateExpiredTime',
            channelCountdown : 'getListChannelCountdown',
            totalNominalTagihan : 'getTotTagihan',
            totalAdmin : 'getTotAdmin',
            detailPromoSelected : 'getDetailPromo',
            totalPromo : 'getPromoAmount'
        }),

        ...mapState({
            promoSelected : (state) => state.promo_selected,

        }),

        feeAdmin() {
            if(this.feeAdminIdBilling != '') return this.feeAdminIdBilling;
            else return this.totalAdmin;

        },
        listNamaTagihan() {
            return this.getDetailTagihanChecked.map((t) => t.wajib_nama).join(', ');
        },
        labelPhone() {
            return (this.ChannelSelectedFromStore?.label_phone) ? this.ChannelSelectedFromStore.label_phone : this.ChannelSelectedFromStore.channel_name;
        },
        timer_difference() {
            // window.console.log(parseInt(this.time_expired.unix()), parseInt(moment().unix()));
            return parseInt(this.time_expired.unix()) - parseInt(this.time_now);
        },
        timer_seconds(){
            if(this.timer_difference <= 0) return 0;
            let seconds = this.timer_difference;
            return seconds %= 60;
        },
        timer_minutes(){
            if(this.timer_difference <= 0) return 0;
            let seconds = this.timer_difference;
            let minutes = Math.floor(seconds / 60);
            return minutes %= 60
        },
        timer_hours(){
            if(this.timer_difference <= 0) return 0;
            let seconds = this.timer_difference;
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            return hours %= 24
        },
        timer_days(){
            if(this.timer_difference <= 0) return 0;
            let seconds = this.timer_difference;
            let minutes = Math.floor(seconds / 60);
            let hours = Math.floor(minutes / 60);
            let day = Math.floor(hours / 24);
            return day
        }
    },
    watch : {
        timer_difference(val){
            if(val > 0 && this.t == ""){

                this.t = setInterval(() => {
                    this.time_now = moment().unix();
                }, 1000);
            } else {
                // window.console.log("masuk");
                if(val <= 0){
                    clearInterval(this.t);
                    if(this.channelCountdown.indexOf(this.ChannelSelectedFromStore.value) == "-1") return false;
                    setTimeout(async () => {
                        
                        try{
                            const requestAccessToken = await Axios.get(urlNodeJs + "/api/auth/access_token", {
                                headers : {
                                    "X-API-KEY" : paymentApiKey,
                                }
                            });
    
                            const access_token = requestAccessToken?.data?.data?.accessToken ?? null;
                            headersPaymentApi.headers['X-ACCESS-TOKEN'] = access_token;
                            
                            let checkRequestIdBillingToken, checkRequestIdBillingResponse;
            
                            const id_billing = this.$store.getters.getIdBilling;
                            checkRequestIdBillingToken = await Axios.post(urlNodeJs + "/token", {id_billing : id_billing}, headersPaymentApi);

                            const requestIdBillingTokenSecretData = checkRequestIdBillingToken?.data?.secretData ?? null;
                            if(!requestIdBillingTokenSecretData) throw new Error("Secret Data Missing");
        
                            checkRequestIdBillingResponse = await Axios.post(urlNodeJs + "/payment/api/check_id_billing", {payload : requestIdBillingTokenSecretData}, headersPaymentApi);
                            
                            const requestedIdBillingStatus = checkRequestIdBillingResponse?.data?.transaction_status ?? null;
                            if(requestedIdBillingStatus){
                                
                                if(requestedIdBillingStatus == "settlement"){
                                    this.$store.commit("setBillingExists", checkRequestIdBillingResponse.data);
                                    this.$router.push({name : 'payment_success', query : {appRoute : this.$route.query.appRoute}});
                                    
                                } else if(requestedIdBillingStatus == "expire"){
                                    this.$store.commit("setBillingExists", checkRequestIdBillingResponse.data);
                                    this.$router.push({name : 'expired', query : {appRoute : this.$route.query.appRoute}});

                                }
                                this.isLoading = false;

                                return true;
                            }
                        } catch (err) {
                            window.console.log(err);
                            this.isLoading = false;

                            const status_code = err?.response?.status ?? 500;
                            if(status_code == 401){
                                await this.$swal({
                                    icon : 'warning',
                                    title : '',
                                    text : 'Sesi Telah Berakhir',
                                    confirmButtonText: 'Reload Page',
                                    allowOutsideClick: false
                                });
                                this.$router.go(0)
                            }
                            return false;
                        }
                    }, 5000)
                }
            }
        }
    }
}
</script>

<style scoped>
    .custom-padding {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .title-kewajiban{
        min-height: 50px;
        background: #b6b8b6;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-weight: bold;
        margin: 15px 0;
    }

    .card-channel{
        display: flex;
        flex-direction: column;
    }

    .channel-name{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 2rem;
    }

    .channel-logo{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .channel-selected{
        border: 1px solid #1797ff !important;
    }

    .card{
        border: 1px solid #aaabad;
    }

    .text-right{
        text-align: right;
    }

    .collapsed{
        background-color: rgb(248, 248, 251);
        border-color: rgb(248, 248, 251);
        color: #000;
    }

    .btn-acccordion-header {
        border-radius: 0.55rem;
    }
    .alert-copy{
        border-radius: 0.55rem !important;
    }

    .group-btn-jump{
        gap : 0.1rem;
    }
    .group-btn-jump a {
        flex : 1
    }

    .card-qr{
        border-radius: 0.55rem;
        border: 0 !important;
    }
    .qr-section img {
        border-radius: 0.75rem !important;
    }
    .qr-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap : 0.7rem;
        justify-content: center;
    }

    .qr-section *{
        position: relative;
    }
    .btn-download-qr{
        background-color: #17a2b8;
        border-color: #17a2b8;
        color: white;
    }
</style>